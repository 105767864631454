import React, { Component } from 'react';
import DesktopGetStartedSteps from './DesktopLayout/GetStartedSteps';
import MobileGetStartedSteps from './MobileLayout/GetStartedSteps';
import PageHeader from '../PageComponents/PageHeader';
import {Link} from 'gatsby'
export default class GetStarted extends Component {
	constructor(props){
		super(props);
		this.state = {
			steps: this.props.steps,
			uploads: this.props.uploads,
			user: {},
			verified: false,
		}
	}
	componentDidMount(){
		window.addEventListener('resize', this.updateWindowDimensions.bind(this));
		this.updateWindowDimensions()
		if(this.props.user){
			if(this.props.user.verified){
				this.setState({
					verified: this.props.user.verified
				})
			}
		}
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions(){
		if(window.innerWidth < 1000){
		  	this.setState({desktopSize: false})
		}
		if(window.innerWidth >= 1000){
		  	this.setState({desktopSize: true})
		}
	}
	render() {
		let desktop = this.state.desktopSize === true;
		let {verified} = this.state;
		return (
			<section className="grey pb-5 pt-0">
				<section className="nullBot">
					<PageHeader  mainHeading="Get started" subHeading="6 easy steps"/>
					{verified?
						null:
						<p style={{margin: '40px 0', zIndex: '100', position: 'relative', textAlign: 'center'}}>
							<Link to="/signup" className="bg-white rounded-lg shadow-md hover:shadow-lg text-secondary text-1xl py-3 px-4 font-medium hover:no-underline">
								Click here To unlock all online tools
							</Link> 
						</p>	
					}
				</section>
				<div className="mb-5">
					{desktop?
						<DesktopGetStartedSteps steps={this.props.steps} uploads={this.props.uploads} paper={this.props.paper} uid={this.props.uid}/>
					:
						<MobileGetStartedSteps steps={this.props.steps} uploads={this.props.uploads} paper={this.props.paper} uid={this.props.uid}/>
					}
				</div>
			</section>
		);
		
		
	}
}

