import React, { Component } from 'react';
import '../../../Content/css/getStartedStepsDesktop.css';
import { animateScroll as scroll} from 'react-scroll'

import Gif1 from '../../../Content/images/getStartedGifs/Step-1.gif';
import Gif2 from '../../../Content/images/getStartedGifs/Step-2.gif';
import Gif3 from '../../../Content/images/getStartedGifs/Step-3.gif';
import Gif4 from '../../../Content/images/getStartedGifs/Step-4.gif';
import Gif5 from '../../../Content/images/getStartedGifs/Step-5.gif';
import Gif6 from '../../../Content/images/getStartedGifs/Step-6.gif';
import {Link} from 'gatsby' 
import { connect } from "react-redux";
import { addNotification } from "../../../redux/actions/";
import {Event} from '../../Analytics';
let gravURL = process.env.REACT_APP_GRAV_URL;
const pdfJournal = `${gravURL}/uploads/GetStartedWorkSheets/journal.pdf`;
const pdfShoppingList = `${gravURL}/uploads/GetStartedWorkSheets/shopping-list.pdf`;
const pdfBodyMeasurement = `${gravURL}/uploads/GetStartedWorkSheets/body-measurements.pdf`;
function mapDispatchToProps(dispatch) {
	return {
        addNotification: notification => dispatch(addNotification(notification)),
	};
}
class GetStartedSteps extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	playingGif: '',
	  	resourceLabel: '',
		downloadScreenOpen: false,
		activeLink: '',
		linktoFile: false  
	  };
	}
	handleGif1(){
		Event("Get Started", "Step 1 Clicked", `View Product Programme`);
		this.setState({playingGif: 'gif1'});
		setTimeout(()=>{	
			let mainSphere2 = document.getElementById('mainSphereImage2');
			let mainSphere = document.getElementById('mainSphereImage');
			if(mainSphere){mainSphere.setAttribute('src', Gif1)}
			if(mainSphere2){mainSphere2.setAttribute('src', Gif1)}
		},10)
		scroll.scrollTo(500, {
			duration: 100,
			smooth: true,
		}); 
		this.setState({
			activeLink: '/products/alpha-lipid-sdii-programme',
			linktoFile: false,
		})
		this.props.addNotification(`<div>View the Product Programme<br/><a href='/products/alpha-lipid-sdii-programme' class="rounded-lg underline text-blue-500 inline-block">View Product</a></div>`)
	}
	handleGif2(){
		if(this.state.playingGif !== 'gif2'){
			this.setState({playingGif: 'gif2', downloadScreenOpen: false});
			setTimeout(()=>{	
				let mainSphere2 = document.getElementById('mainSphereImage2');
				let mainSphere = document.getElementById('mainSphereImage');
				
				if(mainSphere){mainSphere.setAttribute('src', Gif2)}
				if(mainSphere2){mainSphere2.setAttribute('src', Gif2)}
			},10)
			scroll.scrollTo(500, {
			 	duration: 100,
	  			smooth: true,
	  		}); 
			
			if(this.props.paper === false){
				this.setState({
					resourceLabel: 'View Meal Plans',
					linktoFile: false,
					activeLink: '/profile/progress-tracker'
				})
				Event("Get Started", "Step 2 Clicked", `Go to progress tracker`);
				this.props.addNotification(`<div>Your profile can track your progress<br/><a href="/profile/progress-tracker" class="rounded-lg underline text-blue-500 inline-block">View Progress Tracker</a></div>`)
			} 
			else {
				this.setState({
					linktoFile: true,
				})
				
				setTimeout(()=>{
					let sphereLink = document.getElementById('download_link');
					Event("Get Started", "Step 2 Clicked", `Download Body Measurement Form`);
					sphereLink.setAttribute('href', pdfBodyMeasurement)
					this.props.addNotification(`<div>Progress Tracker Measurement Form<br/><a href=${pdfBodyMeasurement} download class="rounded-lg underline text-blue-500 inline-block" target="0_blank">Download Form</a></div>`)
				}, 300)
			}
		}
	}

	handleGif3(){
		this.setState({
			activeLink: ''
		})
		if(this.state.playingGif !== 'gif3'){
			this.setState({
				playingGif: 'gif3',
				downloadScreenOpen: false,
			})
			setTimeout(()=>{	
				let mainSphere2 = document.getElementById('mainSphereImage2');
				let mainSphere = document.getElementById('mainSphereImage');
				if(mainSphere){mainSphere.setAttribute('src', Gif3)}
				if(mainSphere2){mainSphere2.setAttribute('src', Gif3)}
			},10)
			scroll.scrollTo(500, {
			 	duration: 100,
	  			smooth: true,
	  		});

			if(this.props.paper === false){
				Event("Get Started", "Step 3 Clicked", `View Meal Plans`);
				this.setState({
					activeLink: '/profile/meal-plans',
					linktoFile: false,
				})
				
				this.props.addNotification(`<div>Your profile can create custom meal plans<br/><a href="/profile/meal-plans" class="rounded-lg underline text-blue-500 inline-block">View Your Meal Plans</a></div>`)
	  		} 
	  		else{
				Event("Get Started", "Step 3 Clicked", `View Free Meal Plans`); 
				this.setState({
					activeLink: '/programme/meal-plans',
					linktoFile: false,
				})
	  			this.props.addNotification(`<div>View and download prepared meal plans<br/><a href='/programme/meal-plans' class="rounded-lg underline text-blue-500 inline-block">View Your Meal Plans</a></div>`)
	  		}
  		}

	}
	handleGif4(){
		if(this.state.playingGif !== 'gif4'){
			this.setState({playingGif: 'gif4'})
			setTimeout(()=>{	
				let mainSphere2 = document.getElementById('mainSphereImage2');
				let mainSphere = document.getElementById('mainSphereImage');
				if(mainSphere){mainSphere.setAttribute('src', Gif4)}
				if(mainSphere2){mainSphere2.setAttribute('src', Gif4)}
			},10)
			scroll.scrollTo(500, {
			 	duration: 100,
	  			smooth: true,
	  		}); 
			if(this.props.paper === false){
				Event("Get Started", "Step 4 Clicked", `View User Shopping List`);  
				this.setState({
					activeLink: '/profile/shopping-list',
					linktoFile: false,
				})
				this.props.addNotification(`<div>Access editable shopping lists<br/><a href='/profile/shopping-list' class="rounded-lg underline text-blue-500 inline-block">View Shopping List Page</a></div>`)
	  		} 
	  		else{
				Event("Get Started", "Step 4 Clicked", `Download Shopping List`);  
				this.props.addNotification(`<div>Download printable shopping lists<br/><a download href='${pdfShoppingList}' class="rounded-lg underline text-blue-500 inline-block">Download Shopping List</a></div>`)
				this.setState({
					activeLink: pdfShoppingList,
					linktoFile: true,
				})
			}	
		}
	}
	handleGif5(){
		this.setState({
			playingGif: 'gif5',
		})
		setTimeout(()=>{	
			let mainSphere2 = document.getElementById('mainSphereImage2');
			let mainSphere = document.getElementById('mainSphereImage');
			if(mainSphere){mainSphere.setAttribute('src', Gif5)}
			if(mainSphere2){mainSphere2.setAttribute('src', Gif5)}
		},10)
			scroll.scrollTo(500, {
				duration: 100,
				smooth: true,
			});
		if(this.props.paper === false){
			Event("Get Started", "Step 5 Clicked", `Go to User Journal`); 
			this.props.addNotification(`<div>Use the online journal to track your progress<br/><a href='/profile/daily-journal' class="rounded-lg underline text-blue-500 inline-block">Download Journal Form</a></div>`)
			this.setState({
				activeLink: '/profile/daily-journal',
				linktoFile: false,
			})	 
		} 
		else{
			Event("Get Started", "Step 5 Clicked", `Download PDF Journal`); 
			this.setState({
				activeLink: pdfJournal,
				linktoFile: true,
			})
			this.props.addNotification(`<div>Download a journal form to help you track your progress<br/><a download href='${pdfJournal}' class="rounded-lg underline text-blue-500 inline-block">Download Journal Form</a></div>`)
		}	
	}
	handleGif6(){
		Event("Get Started", "Step 6 Clicked", `Uriscan Ketone`);  
		this.setState({
			playingGif: 'gif6',
			downloadScreenOpen: false,
		})
		setTimeout(()=>{	
			let mainSphere2 = document.getElementById('mainSphereImage2');
			let mainSphere = document.getElementById('mainSphereImage');
			if(mainSphere){mainSphere.setAttribute('src', Gif6)}
			if(mainSphere2){mainSphere2.setAttribute('src', Gif6)}
		},10)
		scroll.scrollTo(500, {
			duration: 100,
			smooth: true,
		});
		this.setState({
			activeLink: '/products/uriscan-ketone',
			linktoFile: false,
		})
		this.props.addNotification(`<div>View the Uriscan Ketone Strips product page<br/><a href='/products/uriscan-ketone' class="rounded-lg underline text-blue-500 inline-block">View Product</a></div>`)
	}
	render() {
		let {
			step1Image,
			step2Image,
			step3Image,
			step4Image,
			step5Image,
			step6Image,
			stepsMainImage
		} = this.props.steps;
		let downloadRequired = this.state.linktoFile === true;
		return (

			<div className="getStartedStepsWrapper clearfix">
				<div className="clearfix">
					<button className="step z-20 relative step1Wrapper text-left" onClick={this.handleGif1.bind(this)}>
						<h3>{this.props.steps.step1Heading}</h3>
						{step1Image &&
						<img  src={this.props.uploads + this.props.steps.step1Image} className="step1Image" alt={this.props.steps.step1Heading}/>
						}
						<p><strong>{this.props.steps.step1SubHeading}</strong><br/>{this.props.steps.step1Text}</p>
						<div className="stepLabelWrapper"><span className="stepLabel">Step 1</span></div>
					</button>
					<button className="step z-20 relative step2Wrapper text-left" onClick={this.handleGif2.bind(this)}>
						<h3>{this.props.steps.step2Heading}</h3>
						<p><strong>{this.props.steps.step2SubHeading}</strong> {this.props.steps.step2Text}</p>
						{step2Image &&
						<img src={this.props.uploads + this.props.steps.step2Image}className="step2Image"alt={this.props.steps.step2Heading}/>
						}
						<div className="stepLabelWrapper">
							<span className="stepLabel">Step 2</span>
						</div>
					</button>
				</div>
				<div className="clearfix">
					<button className="step z-20 relative step6Wrapper text-left" onClick={this.handleGif6.bind(this)}>
						<h3>TEST<br/>{this.props.steps.step6Heading}</h3>
						<p><strong>{this.props.steps.step6SubHeading}</strong> {this.props.steps.step6Text}</p>
						{step6Image &&
						<img  src={this.props.uploads + this.props.steps.step6Image} className="step6Image" alt={this.props.steps.step6Heading}/>
						}
						<div className="stepLabelWrapper"><span className="stepLabel">Step 6</span></div>
					</button>
					<div className="stepSphereWrapper relative z-10 text-left">
							{downloadRequired?
							<a id="download_link" style={{borderRadius: '50%'}} className="block overflow-hidden" download target="0_blank" href={this.state.activeLink}>
								<div className="stepsMainImageSphere">
								{stepsMainImage &&
									<img src={this.props.uploads + this.props.steps.stepsMainImage} className="stepsMainImage" alt="Get Started Page" id="mainSphereImage" width="500px" height="500px"/>
								}
								</div>
							</a>
							:
							<Link to={this.state.activeLink}>
								<div className="stepsMainImageSphere">
								{stepsMainImage &&
									<img src={this.props.uploads + this.props.steps.stepsMainImage} className="stepsMainImage" alt="Get Started Page" id="mainSphereImage2" width="500px" height="500px"/>
								}
								</div>
							</Link>
							}
					</div>
					<button className="step z-20 relative step3Wrapper text-left" onClick={this.handleGif3.bind(this)}>
						<h3>CHOOSE<br/>{this.props.steps.step3Heading}</h3>
						<p><strong>{this.props.steps.step3SubHeading}</strong> {this.props.steps.step3Text}</p>
						{step3Image &&
						<img  src={this.props.uploads + this.props.steps.step3Image} className="step3Image" alt={this.props.steps.step3Heading}/>
						}
						<div className="stepLabelWrapper">
							<span className="stepLabel">Step 3</span>
						</div>
					</button>
				</div>
				<div className="clearfix">
					<button className="step z-20 relative step5Wrapper text-left" onClick={this.handleGif5.bind(this)}>
						<h3>{this.props.steps.step5Heading}</h3>
						{step5Image &&
						<img src={this.props.uploads + this.props.steps.step5Image}className="step5Image"alt={this.props.steps.step5Heading}/>
						}
						<div className="relativeTextWrapper">
							<p><strong>{this.props.steps.step5SubHeading}</strong> {this.props.steps.step5Text}</p>
						</div>
						<div className="stepLabelWrapper">
							<span className="stepLabel">Step 5</span>
						</div>
					</button>
					<button className="step z-20 relative step4Wrapper text-left" onClick={this.handleGif4.bind(this)}>
						<h3>{this.props.steps.step4Heading}</h3>
						{step4Image &&
						<img src={this.props.uploads + this.props.steps.step4Image}
							className="step4Image"
							alt={this.props.steps.step4Heading}
						/>
						}
						<div className="relativeTextWrapper">
							<p><strong>{this.props.steps.step4SubHeading}</strong> {this.props.steps.step4Text}</p>
						</div>
						<div className="stepLabelWrapper">
							<span className="stepLabel">Step 4</span>
						</div>
					</button>
				</div>
			</div>
		);
	}
}
const connectedSteps = connect(
	null,
	mapDispatchToProps
  )(GetStartedSteps);
  
export default connectedSteps;