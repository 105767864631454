import React, { Component } from 'react';
import Helmet from 'react-helmet'
import GetStarted from './GetStarted';
import { connect } from "react-redux";
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
const mapStateToProps = (state, props) => {
    return {user: state.user, uid: state.uid, pageContext: props.pageContext};
};
class GetStartedPage extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	gravContent: this.props.pageContext.frontmatter,
	  	uploads: gravURL + '/uploads/images/',
	  };
	}
	componentDidMount() {
		window.scrollTo(0,0)
	}
	
	render() {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc} = this.props.pageContext.head;
		const paper = this.props.uid.length === 0;
		return (
			<main>					
				<Helmet>
					<title>Get Started | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={gravURL + shareImg} />
					<meta property="og:image:url" content={gravURL + shareImg}/>
					<meta name="twitter:image" content={gravURL + shareImg}/>
					<meta name="twitter:card" content={gravURL + shareImg}/>
				</Helmet>	
					<GetStarted steps={this.state.gravContent} 
						uploads={this.state.uploads} 
						paper={paper} 
						uid={this.props.uid}
						user={this.props.user}
					/>
			</main>
		);
	}
}
const ConnectedGetStarted = connect(mapStateToProps, null)(GetStartedPage);

export default ConnectedGetStarted;