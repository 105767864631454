import React, { Component } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import '../../../Content/css/getStartedStepsMobile.css';
import pdfJournal from '../../../Content/pdf/GetStartedWorkSheets/journal.pdf';
import pdfShoppingList from '../../../Content/pdf/GetStartedWorkSheets/shopping-list.pdf';
import pdfBodyMeasurement from '../../../Content/pdf/GetStartedWorkSheets/body-measurements.pdf';
import Gif1 from '../../../Content/images/getStartedGifs/Step-1.gif';
import Gif2 from '../../../Content/images/getStartedGifs/Step-2.gif';
import Gif3 from '../../../Content/images/getStartedGifs/Step-3.gif';
import Gif4 from '../../../Content/images/getStartedGifs/Step-4.gif';
import Gif5 from '../../../Content/images/getStartedGifs/Step-5.gif';
import Gif6 from '../../../Content/images/getStartedGifs/Step-6.gif';
import Event from '../../Analytics';
export default class GetStartedSteps extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	resourceLabel: '',
	  	resourceChosen: false,
	  	downloadableLink: '',
	  	downloadScreen: false,
	  };
	}
	handleGif1(){
		Event("Get Started", "Step 1 Clicked", `Go To Products Page`);  
		this.setState({resourceChosen: true})
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif1)
		scroll.scrollTo(155, {
		 	duration: 100,
  			smooth: true,
		  }); 
		Event("Get Started", "Step 1 Clicked", `View Product Programme`);
		this.setState({resourceLabel: 'View Product'});
		sphereLink.setAttribute('href', '/products/alpha-lipid-sdii-programme')	
		

	}
	handleGif2(){
		this.setState({resourceChosen: true})
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif2)
		scroll.scrollTo(155, {
		 	duration: 100,
  			smooth: true,
  		}); 
		
		if(this.props.paper === false){
			this.setState({
				resourceLabel: 'View Meal Plans'
			})
			Event("Get Started", "Step 2 Clicked", `Go to progress tracker`);
			sphereLink.setAttribute('href', '/profile/progress-tracker');	
  		} 
  		else{
			sphereLink.setAttribute('href', pdfBodyMeasurement);
			Event("Get Started", "Step 2 Clicked", `Download Body Measurement Form`);
			this.setState({resourceLabel: 'Download Body Measurement Form'})
  		}
  		
	}
	handleGif3(){
		this.setState({resourceChosen: true})
		
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif3)
		scroll.scrollTo(155, {
		 	duration: 100,
  			smooth: true,
  		}); 
		if(this.props.paper === false){
			this.setState({
				resourceLabel: 'View Meal Plans'
			})
			Event("Get Started", "Step 3 Clicked", `View Meal Plans`);
			sphereLink.setAttribute('href', '/profile/meal-plans')	
  		} 
  		else{
  			this.setState({
				resourceLabel: 'View Meal Plans'
			})
			sphereLink.setAttribute('target', '0_blank')
			Event("Get Started", "Step 3 Clicked", `View Free Meal Plans`);  
  			sphereLink.setAttribute('href', '/programme/meal-plans')
  		}
	}
	handleGif4(){
		this.setState({resourceChosen: true})
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif4)
		scroll.scrollTo(155, {
		 	duration: 100,
  			smooth: true,
  		}); 

		if(this.props.paper === false){
			this.setState({
				resourceLabel: 'Download Shopping List'
			})
			sphereLink.setAttribute('href', '/profile/shopping-list')
			Event("Get Started", "Step 4 Clicked", `View User Shopping List`);  
  		} 
  		else{
  			this.setState({
				resourceLabel: 'My Shopping List'
			})
			sphereLink.setAttribute('target', '0_blank')
			Event("Get Started", "Step 4 Clicked", `Download Shopping List`);  
  			sphereLink.setAttribute('href', pdfShoppingList)
  		}
	}

	handleGif5(){
		this.setState({resourceChosen: true})
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif5)
			scroll.scrollTo(155, {
			 	duration: 100,
	  			smooth: true,
	  		});
		if(this.props.paper === false){
			this.setState({
				resourceLabel: 'Open Journal',
			})
			Event("Get Started", "Step 5 Clicked", `Go to User Journal`);  
			sphereLink.setAttribute('href', '/profile/daily-journal');			 
  		} 
  		else{
  			this.setState({
				resourceLabel: 'Download PDF Journal'
			})
			Event("Get Started", "Step 5 Clicked", `Download PDF Journal`);  
  			sphereLink.setAttribute('target', '0_blank')
  			sphereLink.setAttribute('href', pdfJournal)
  		}
	}
	handleGif6(){
		this.setState({resourceChosen: true})
		let mainSphere = document.getElementById('mainSphereImage');
		let sphereLink = document.getElementById('mainSphereLink');
		mainSphere.setAttribute('src', Gif6)
		scroll.scrollTo(155, {
		 	duration: 100,
  			smooth: true,
  		});
	
		Event("Get Started", "Step 6 Clicked", `Uriscan Ketone`);  
		sphereLink.setAttribute('href', '/products/uriscan-ketone');
		this.setState({
			resourceLabel: 'View Product'
		})			 
  		
	}
	render() {
		let resourceChosen = this.state.resourceChosen === true;
		return (
			<div className="clearfix">
				<section className="mobileStepSection">
					<div>
						<div>
							<span>
								<img src={this.props.uploads + this.props.steps.stepsMainImage} 
									className="stepsMainImage"
									alt="Get Started Page"
									id="mainSphereImage"
								/>
							</span>
						</div>
					</div>
				</section>
				<div className="getStartedResourceNav text-center" data-visible={resourceChosen}>
					<a href="/" id="mainSphereLink" className="button darkButton">{this.state.resourceLabel}</a>
				</div>
				<section className="mobileStepSection">
					<button onClick={this.handleGif1.bind(this)}>
						<div className="mobileStepLabel"><span>Step 1</span></div>
						<div className="mobileStepContainer">
							<h3>{this.props.steps.step1Heading}</h3>
							<img src={this.props.uploads + this.props.steps.step1Image} alt={this.props.steps.step1Heading}/>
							<hr/>
							<p><strong>{this.props.steps.step1SubHeading}</strong><br/>{this.props.steps.step1Text}</p>
						</div>
					</button>
				</section>
				<section className="mobileStepSection">
					<button onClick={this.handleGif2.bind(this)}>
						<div className="mobileStepLabel"><span>Step 2</span></div>
						<div className="mobileStepContainer">
							<h3>{this.props.steps.step2Heading}</h3>
							<img src={this.props.uploads + this.props.steps.step2Image} alt={this.props.steps.step2Heading}/>
							<hr/>
							<p><strong>{this.props.steps.step2SubHeading}</strong> {this.props.steps.step2Text}</p>
						</div>
					</button>
				</section>
				<section className="mobileStepSection">
					<button onClick={this.handleGif3.bind(this)}>
						<div className="mobileStepLabel">
							<span>Step 3</span>
						</div>
						<div className="mobileStepContainer">
							<h3>CHOOSE<br/>{this.props.steps.step3Heading}</h3>
							<img src={this.props.uploads + this.props.steps.step3Image} alt={this.props.steps.step3Heading}/>
							<hr/>
							<p><strong>{this.props.steps.step3SubHeading}</strong> {this.props.steps.step3Text}</p>
						</div>
					</button>
				</section>
				<section className="mobileStepSection">
					<button onClick={this.handleGif4.bind(this)}>
						<div className="mobileStepLabel">
							<span>Step 4</span>
						</div>
						<div className="mobileStepContainer">
							<h3>{this.props.steps.step4Heading}</h3>
							<img  src={this.props.uploads + this.props.steps.step4Image} alt={this.props.steps.step4Heading}/>
							<hr/>
							<div>
								<p><strong>{this.props.steps.step4SubHeading}</strong> {this.props.steps.step4Text}</p>
							</div>
						</div>
					</button>
				</section>
				<section className="mobileStepSection">
					<button onClick={this.handleGif5.bind(this)}>
						<div className="mobileStepLabel">
							<span>Step 5</span>
						</div>
						<div className="mobileStepContainer">
							<h3>{this.props.steps.step5Heading}</h3>
							<img  src={this.props.uploads + this.props.steps.step5Image} alt={this.props.steps.step5Heading}/>
							<hr/>
							<div>
								<p><strong>{this.props.steps.step5SubHeading}</strong> {this.props.steps.step5Text}</p>
							</div>
						</div>
					</button>	
				</section>
				<section className="mobileStepSection">
					<button onClick={this.handleGif6.bind(this)}>
						<div className="mobileStepLabel">
							<span>Step 6</span>
						</div>
						<div className="mobileStepContainer">
							<h3>TEST<br/>{this.props.steps.step6Heading}</h3>
							<img 
								src={this.props.uploads + this.props.steps.step6Image}
								alt={this.props.steps.step6Heading}
								className="step6Image"
							/>
							<hr/>
							<p><strong>{this.props.steps.step6SubHeading}</strong> {this.props.steps.step6Text}</p>
						</div>	
					</button>
				</section>
				
			</div>
		);
	}
}
